<template>
  <div class="login-box">
    <div class="login-logo">
      <b>{{ information.title }}</b>
    </div>
    <!-- /.login-logo -->
    <div class="card">
      <div class="card-body login-card-body">
        <p class="login-box-msg" v-show="login_message != ''">{{ login_message }}</p>

        <form action="" method="post">
          <div class="input-group mb-3">
            <input type="text" class="form-control" placeholder="帳號" v-model="account">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-user"></span>
              </div>
            </div>
          </div>
          <div class="input-group mb-3">
            <input type="password" class="form-control" placeholder="密碼" v-model="password" autocomplete="true">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
          </div>


          <div class="input-group mb-3" v-show="show_verification">
            <input type="text" class="form-control" placeholder="驗證碼" v-model="verification_code">
            <div class="input-group-prepend">
              <span class="input-group-text"><img class="verification-code" v-bind:src="verification_code_url" /></span>
            </div>
            <div class="input-group-prepend">
              <div class="input-group-text refresh" @click="refresh_verification_code">
                <span class="fas fa-redo"></span>
              </div>
            </div>
          </div>



          <div class="row">
            <div class="col-8">
              <div class="icheck-primary">
                <input type="checkbox" id="remember">
                <label for="remember">
                  記住我
                </label>
              </div>
            </div>
            <!-- /.col -->
            <div class="col-4">
              <button type="button" class="btn btn-success btn-block" @click="check_login">登 入</button>
            </div>
            <!-- /.col -->
          </div>
          <div class="text-center">
            <p class="extra">
              <a href="forgot-password.html">忘記密碼</a> - <a href="register.html" class="text-center">註冊</a>
            </p>
          </div>
        </form>

        <div class="social-auth-links text-center mb-3">
          <p>- 快速登入 -</p>
          <a href="#" class="btn btn-block btn-primary">
            <i class="fab fa-facebook mr-2"></i> Sign in using Facebook
          </a>
          <a href="#" class="btn btn-block btn-danger">
            <i class="fab fa-google-plus mr-2"></i> Sign in using Google+
          </a>
        </div>
        <!-- /.social-auth-links -->
      </div>
      <!-- /.login-card-body -->
    </div>
  </div>
</template>

<script>
export default {
  data() {

    // 判斷是否顯示驗證碼
    let show_verification = (this.$microlink.information.verification != undefined && this.$microlink.information.verification != '');
    return {
      verification_code_url: this.$microlink.base_api_url + '/captcha/get',
      information: this.$microlink.information,
      show_verification: show_verification,
      login_message: '',
      account: '',
      password: '',
      verification_code: ''
    }
  },
  methods: {
    refresh_verification_code: function () {
      this.verification_code_url = this.$microlink.base_api_url + '/captcha/get?v=' + Math.random().toString().substring(2);
    },
    check_login: function () {

      // 如果沒設定登入驗證API
      if (this.information.login_check == undefined || this.information.login_check == '') {
        this.login_message = '登入驗證API尚未設定';
        return;
      }

      let api_url = this.$microlink.base_api_url + this.information.login_check;

      let $this = this;

      this.$microlink.api_post(api_url, {
        'account': this.account,
        'password': this.password,
        'verification_code': this.verification_code
      }, true, function (response) {
        if (response.status == 'success') {
          location.href = '#/';
        }
        else {
          $this.login_message = response.message;

          // 讓整個 class="card" 的區塊能夠有左右振動效果
          document.querySelector('.card').classList.add('shake');
          setTimeout(function () {
            document.querySelector('.card').classList.remove('shake');
          }, 500);

        }
      })
    }
  }
}
</script>

<style>
html,
body {
  background-color: #5382b5;
  background-image: url('../assets/img/login-background.png');
}

div.login-logo {
  color: white;
}

p.login-box-msg {
  color: red;
}

div.login-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 25%);
  border-radius: 5px;
}

p.extra {
  text-align: center;
  margin: 10px 0px;
}

img.verification-code {
  height: 24px;
}

div.refresh {
  margin-left: 3px;
  cursor: pointer;
}

/**
 * 左右振動效果
 */
.card.shake {
  animation: shake 0.5s;
}
</style>